<template>
	<StudentInterviews></StudentInterviews>
</template>

<script>
import StudentInterviews from "@/components/shared/student/Interviews.vue";

export default {
	name: "AdminStudentOverview",

	components: {
		StudentInterviews,
	},
};
</script>

<style></style>
